<template lang="pug">
v-card.p-1.w-100
  v-card-title.text-uppercase {{ $t('viewPrice') }} {{ $t(row.type_of_form) }} {{ row.type_document.value }}
  v-card-text
  div(v-if="itemsFuture.results?.length")
    h3 {{ $t('futurePrice') }}
    TableV2(
      :isViewAdd="false"
      :headers="headers"
      :items="itemsFuture.results"
      :pageCount="itemsFuture.count"
      :currentPage="itemsFuture.current"
      :querySearch="getDocumentsFuture"
      :isLoading="isLoading.future"
      isHiddenTableSearch
      showExpand
      isNotMounted
      componentEdit="BackOfficeDocumentsPriceOfflineAdd"
      :actions="actions"
      :iconExpandedOpen="iconExpandedOpen"
    )
  div(v-if="itemsPast.results?.length").mt-2
    h3 {{ $t('pastPrice') }}
    TableV2(
      :isViewAdd="false"
      :headers="headers"
      :items="itemsPast.results"
      :pageCount="itemsPast.count"
      :currentPage="itemsPast.current"
      :querySearch="getDocumentsPast"
      :isLoading="isLoading.past"
      isHiddenTableSearch
      showExpand
      isNotMounted
      :actions="actions"
      :iconExpandedOpen="iconExpandedOpen"
      componentEdit="BackOfficeDocumentsPriceOfflineEdit"
    )
</template>
<script>
import { hideDetailed, deleteConfirmation } from '@/mixins/main'
import Paginate from '@/components/atoms/Paginate'
import { mapActions } from 'vuex'

export default {
  name: 'BackOfficeDocumentsPriceInfo',
  props: {
    row: { type: Object, default: () => ({}) }
  },
  components: {
    Paginate
  },
  data () {
    return {
      headers: [
        { value: 'type_of_form', text: this.$t('typeDoc'), sortable: false },
        { value: 'date_start', text: this.$t('dateEffective'), sortable: false },
        { value: 'full_price', text: this.$t('coming'), sortable: false },
        { value: 'to_sqc', text: this.$t('toSQC'), cellClass: 'border-r', class: 'border-r', sortable: false },
        { value: 'to_qd', text: this.$t('toQD'), sortable: false },
        { value: 'to_td', text: this.$t('toTD'), sortable: false },
        { value: 'to_sc', text: this.$t('toSC'), sortable: false },
        { value: 'to_crewing', text: this.$t('toCrewingManager'), sortable: false },
        { value: 'to_medical', text: this.$t('toMedical'), sortable: false },
        { value: 'to_cec', text: this.$t('toCEC'), sortable: false },
        { value: 'to_mrc', text: this.$t('toMRC'), sortable: false },
        { value: 'to_portal', text: this.$t('toPortal'), cellClass: 'border-r', class: 'border-r', sortable: false },
        { value: 'sum_to_distribution', text: this.$t('all'), sortable: false },
        { value: 'profit', text: this.$t('profit') },
        { value: 'event', text: this.$t('actions'), class: 'mw-0', sortable: false },
        { text: '', value: 'data-table-expand' }
      ],
      isLoading: {
        past: false,
        future: false
      },
      hideDetailed,
      actions: [
        {
          id: 1,
          tooltip: 'tooltip.delete',
          action: (item) => this.deletePositionPrice(item),
          color: '#F73E07',
          name: 'mdi-trash-can-outline',
          checkAccess: () => true,
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#F73E07', outlined: false }
        }
      ],
      iconExpandedOpen: {
        name: 'mdi-pen',
        color: 'blue',
        tooltip: 'tooltip.edit'
      },
      itemsFuture: {},
      itemsPast: {}
    }
  },
  mounted () {
    this.getDocumentsFuture({ page: 1, page_size: 10 })
    this.getDocumentsPast({ page: 1, page_size: 10 })
  },
  methods: {
    ...mapActions([
      'getListOfflineDocumentsPricePastValue',
      'getListOfflineDocumentsPriceFutureValue',
      'deleteOfflineDocumentPriceOnline'
    ]),
    async getDocumentsFuture (filter) {
      this.isLoading.future = true
      this.itemsFuture = await this.getListOfflineDocumentsPriceFutureValue({
        filter,
        id: this.row.id
      })
      this.isLoading.future = false
    },
    async getDocumentsPast (filter) {
      this.isLoading.past = true
      this.itemsPast = await this.getListOfflineDocumentsPricePastValue({
        filter,
        id: this.row.id
      })
      this.isLoading.past = false
    },
    deletePositionPrice (item) {
      deleteConfirmation(this).then(async (confirmation) => {
        if (confirmation) {
          await this.deleteOfflineDocumentPriceOnline({ id: item.id })
          this.$notification.success('priceEtiDeleted')
          this.getListOfflineDocumentsPriceFutureValue()
          this.getListOfflineDocumentsPricePastValue()
        }
      })
    }
  }
}
</script>
